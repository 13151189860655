import React from "react"
import { css } from "@emotion/react"
import FourImages from "./fourimages"
import ImageGallery from "./imagegallery"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Button } from 'react-bootstrap'

/*
  Im unten definierten Stil werde
  (1) Die Margins von dem festgelegt
  (2) Der List-Stil definiert, damit als list-icon statt des Punkts
  das SVG angezeigt wird (muss dann unter /static/xx.svg liegen)
  Wir können hier leider nicht auf Komponenten (wie z.B. fontawesome-components)
  zurückgreifen, da der Content ja komplett automatisch aus dem Markdown gerendert wird
*/

const generatedContentStyle = css`
  margin-left: 1rem;
  margin-right: 1rem;

  ul {
    list-style: none;
    padding: 0;
    margin-left: 2em;
  }
  li {
    padding-left: 1em;
  }
  li:before {
    content:''; 
    display:inline-block; 
    height:1em; 
    width:1em; 
    background-image:url('/img/checksquare.svg'); 
    background-size:contain; 
    background-repeat:no-repeat; 
    margin-left: -2.0em;
    width: 2.0em;
  }
`

export default function PortfolioSubSiteContent(props) {

  return (
    <div>
      <FourImages>
      {props.imagestop.edges.map((file, index) => {
          return (
            <div key={file.node.id}>
              <Img fluid={file.node.childrenImageSharp[0].fluid}/>
            </div>
          )
        })}
      </FourImages>
      <div>
        <div dangerouslySetInnerHTML={{ __html: props.content.html }} css={generatedContentStyle}></div>
        <div className="text-center mt-5">
          <Link to="/contact/" css={css`text-decoration: "none";`}><Button variant="primary">Termin reservieren</Button></Link>
        </div>
      </div>
      <div className="mt-5">
        <ImageGallery imageData={props.imagesbottom}></ImageGallery>
      </div>
    </div>
  )
}